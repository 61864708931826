<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <Fieldset id="information" :title="$t('Basic Information')">
            <b-row>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('Name') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Name')"
                      @input="generateSlug(model.name)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="*Slug:" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Slug"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.slug"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Slug"
                      @input="generateSlug(model.slug)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="*Link:" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="Link"
                rules="required"
              >
                <b-form-input
                  v-model="model.link"
                  placeholder="Link"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group :label="`*` + $t('Content') + `:`" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="*Content"
                rules="required"
              >
                <b-form-textarea
                  v-model="model.content"
                  :placeholder="$t('Content')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-row class="mt-2">
              <b-col cols="6">
                <b-form-group :label="$t('Position')+`:`" label-cols-md="3">
                  <b-form-input
                    v-model="model.position"
                    type="number"
                    :placeholder="$t('Position')"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="$t('Display on/off')+`:`" label-cols-md="3">
                  <b-form-checkbox
                    v-model="model.is_active"
                    checked="true"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </Fieldset>
          <!-- <Fieldset id="SEO" :title="$t('SEO Optimization')">
            <SEOInfo v-model="model" />
          </Fieldset> -->
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <Fieldset id="avatar" :title="$t('Avatar')">
              <FeatureImage :avatar="avatar" @previewFiles="previewFiles" />
            </Fieldset>
            <div class="text-right">
              <b-button
                variant="primary"
                type="submit"
                class="text-right"
                @click.prevent="validationForm"
              >
                {{ $t('Update') }}
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-destructuring */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },

  mixins: [general],
  data() {
    return {
      required,
      model: {},
      avatar: null,
    }
  },
  created() {
    this.loadDetail()
  },
  methods: {
    generateSlug(name) {
      this.model.slug = this.convertToSlug(name)
    },
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/trademark/${this.$route.params.id}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.detailDefault = { ...res.data.data }
            this.model = await this.getDataTranslation(res.data.data, false)
            this.avatar = this.model.avatar
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const translation = {
              ...this.detailDefault.translation,
            }
            translation[await this.getEditLanguage()] = {
              name: this.model.name,
              // meta_description: this.model.meta_description,
              // meta_keyword: JSON.stringify(this.model.meta_keyword),
              content: this.model.content,
            }
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              position: Number(this.model.position),
              is_active: Number(this.model.is_active === true ? 1 : 0),
              slug: this.model.slug,
              link: this.model.link,
              avatar: this.avatar,
              translation: JSON.stringify(translation),
            }
            if (
              (await this.getEditLanguage()) ===
              JSON.parse(localStorage.getItem('siteID')).language
            ) {
              // Default language = current language -> get current info
              params.name = this.model.name
              // params.meta_description = this.model.meta_description
              // params.meta_keyword = JSON.stringify(this.model.meta_keyword)
              params.content = this.model.content
            } else {
              //  Default language != current langue -> get default info
              params.name = this.detailDefault.name
              // params.meta_description = this.detailDefault.meta_description
              // params.meta_keyword = JSON.stringify(
              //   this.detailDefault.meta_keyword,
              // )
              params.content = this.detailDefault.content
            }
            const res = await Request.put(
              this.$http,
              `${process.env.VUE_APP_API_URL}/trademark/${this.$route.params.id}`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Update Trademark success',
                  },
                })
                this.$router.push('/trademark/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang='scss'>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>

<style lang='scss' scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
